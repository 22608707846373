import React, { useState, useEffect, useMemo } from "react"

import { FormatCurrency } from "../../../utils/utils"
import { MONTH_YEAR_ROLLOVER, GetYearFilterOptions, GetDefaultSelectedYear, FilterRecords } from "../../../../utils/reports"


// @ts-ignore
import * as styles from "../TableStyles.module.css"

function SponsorReportTable():JSX.Element {
  const [records,setRecords] = useState<Array<SponsorRegistrationRecord>>([]),
        [isLoading,setIsLoading] = useState<boolean>(false),
        [errorMessage,setErrorMessage] = useState<string>(""),
        [yearOptions,setYearOptions] = useState<Array<number>>([]),
        [filteredYear,setFilteredYear] = useState<number>()

  function loadRecords () {
    setIsLoading(true)
    fetch(`${process.env.GATSBY_NETLIFY_FUNCTIONS_PATH || ""}/.netlify/functions/reports?t=sponsors`, {
      method: "GET"
    })
    .then((response)=>{
      response.text()
      .then((data:string)=>{
        let response = JSON.parse(data)
        let newRecords = response.data.map((ref:any)=>{
          return {
            ...ref.data,
            last_modified: new Date(ref.ts/1000)
          }
        }).filter((a:any) => { return a ? true : false})
          .filter((a:any) => { return a.payment_status && a.payment_status === "paid" ? true : false})

        setRecords(newRecords)
        setFilteredYear(GetDefaultSelectedYear())
        setYearOptions(GetYearFilterOptions())
        setIsLoading(false)
      })
      .catch((err)=>{
        setIsLoading(false)
        setErrorMessage("Failed to load records")
      })
    })
    .catch((err)=>{
      setIsLoading(false)
      setErrorMessage("Failed to load records")
    })
  }

  useEffect(()=>{
    loadRecords()
  }, [])

  function copyData() {
    let data = records.map((record) => {
      const sponsorData = record as SponsorRegistrationRecord
      return [
        sponsorData.last_modified?.toLocaleString(),
        sponsorData.firstName,
        sponsorData.lastName,
        sponsorData.affiliation,
        sponsorData.address1,
        sponsorData.address2,
        sponsorData.city,
        sponsorData.state,
        sponsorData.zip,
        sponsorData.country,
        sponsorData.telephone,
        sponsorData.email,
        sponsorData.sponsor_name,
        sponsorData.sponsor_website,
        sponsorData.purchase_items?.map((item)=>{return `${item.label} (${item.quantity} @ ${item.price})`}).join('\n'),
        FormatCurrency(record.amount_charged || 0)
      ].join('\t')
    }).join('\n')
    navigator.clipboard.writeText(data)
  }

  function onUpdateFilter(e:React.ChangeEvent<HTMLSelectElement> ) {
    try {
      let newYear :number|undefined
      newYear = parseInt(e.target.value)
      if(newYear && newYear > 2020) {
        setFilteredYear(newYear)
        return
      }
    } catch(err) {
    }
    setFilteredYear(undefined)
  }

  const visibleResults:Array<FormRecord> = useMemo(()=>{
    return FilterRecords(records, filteredYear)
  },[yearOptions,filteredYear,records])


  return (
    <div>
      <div className={styles.infoBar}>
        <div>
          <button onClick={copyData}>Copy</button>
          <p style={{paddingLeft: 10}}>{`${visibleResults.length} Records`}</p>
        </div>
        {isLoading ? (<p>Loading...</p>) : null}
        <div>
          <span className={styles.field}>
            <label title={`Years rollover on the ${MONTH_YEAR_ROLLOVER+1} month `}>Year</label>
            <select value={filteredYear} onChange={onUpdateFilter}>
              <option value="">All</option>
              {yearOptions.map((yr)=>(
                <option key={yr} value={yr}>{`${MONTH_YEAR_ROLLOVER+1}/${yr-1} through ${MONTH_YEAR_ROLLOVER}/${yr}`}</option>
              ))}
            </select>
          </span>
        </div>
      </div>
      {errorMessage ? (<p>{errorMessage}</p> ) : null}
      <div className={styles.tableWrapper}>
        <table className={styles.table}>
          <thead>
            <tr>
              <th>Date</th>
              <th>First Name</th>
              <th>Last Name</th>
              <th>Affiliation</th>
              <th>Address 1</th>
              <th>Address 2</th>
              <th>City</th>
              <th>State</th>
              <th>Zip</th>
              <th>Country</th>
              <th>Telephone</th>
              <th>Email</th>
              <th>Sponsor Name</th>
              <th>Sponsor Website</th>
              <th>Items</th>
              <th>Amount Charged</th>
            </tr>
          </thead>
          <tbody>
            {visibleResults.map((r)=>{
              let record = r as SponsorRegistrationRecord
              return (
              <tr key={record.client_reference_id}>
                <td>{record.last_modified?.toLocaleString()}</td>
                <td>{record.firstName}</td>
                <td>{record.lastName}</td>
                <td>{record.affiliation}</td>
                <td>{record.address1}</td>
                <td>{record.address2}</td>
                <td>{record.city}</td>
                <td>{record.state}</td>
                <td>{record.zip}</td>
                <td>{record.country}</td>
                <td>{record.telephone}</td>
                <td>{record.email}</td>
                <td>{record.sponsor_name}</td>
                <td>{record.sponsor_website}</td>
                <td>{record.purchase_items?.map((item)=>{return `${item.label} (${item.quantity} @ ${item.price})`}).join('\n')}</td>
                <td>{FormatCurrency(record.amount_charged || 0)}</td>
              </tr>
            )})}
          </tbody>
        </table>
      </div>
    </div>
  )
}

export default SponsorReportTable